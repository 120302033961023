import { useMessage } from 'naive-ui';
import { Workspace } from '@robogo/common';
import { apiUser, apiWorkspace } from '~/api';
import { useUserStore } from '~/stores';
import { storeToRefs } from 'pinia';
//用户工作空间
export function useUserWorkspace() {
  const $message = useMessage();
  const userStore = useUserStore();
  const { workspace } = storeToRefs(userStore);

  //加载状态
  const { loading: workspaceLoading, startLoading, closeLoading } = useLoading();

  const workspaces = ref<Workspace[]>();

  //工作空间选项
  const workspaceOptions = computed(() => {
    return workspaces.value?.map((item) => {
      return {
        label: item.name,
        value: item.workspaceId,
      };
    });
  });

  //加载所有工作空间
  async function onLoadWorkspaceAll() {
    if (workspaceLoading.value) return;
    startLoading();
    const { data, success } = await apiWorkspace.findAll();
    closeLoading();
    if (success) {
      workspaces.value = data;
    }
  }

  //选择工作空间
  async function onSelectWorkspace(workspace: Workspace | string): Promise<boolean> {
    let workspaceId;
    if (typeof workspace === 'string') {
      workspaceId = workspace;
    } else {
      workspaceId = workspace?.workspaceId;
    }

    startLoading();
    const { success, message } = await apiUser.selectWorkspace(workspaceId);
    closeLoading();
    if (success) {
      $message.success('Select workspace success');
      await userStore.me();
      if (window) {
        window.location.reload();
      }
      return true;
    }
    $message.error(message);
    return false;
  }

  function userActivedWorkspace(workspaceId: string) {
    return workspace.value?.workspaceId === workspaceId;
  }

  return {
    workspaces,
    workspaceOptions,
    onLoadWorkspaceAll,
    workspaceLoading,
    onSelectWorkspace,
    userActivedWorkspace,
  };
}
